<template>
    <div>
        <other-header></other-header>
        <div class="policy-detail">
            <detail-header last-name="返回" :title="info.TitleName+' 会场'"/>
            <div class="flex-row flex-j-start flex-a-start">
                <!--            <Category @select="onCategorySelect"/>-->
                <!--            <div style="width: 0.2rem"></div>-->
                <refresh-list ref="refreshList" @refresh="onPullDownRefresh" @load="onReachBottom">
                    <empty-page v-if="!list||list.length===0" msg="暂无相关职位"/>
                    <div class="list" style="width: calc(18rem - 0.4rem);;padding: 0 0.1rem">
                        <office-list-cell v-for="(item, index) in list" :key="index" :item="item"></office-list-cell>
                    </div>
                </refresh-list>
            </div>
        </div>
    </div>
</template>

<script>
// policy-detail
import DetailHeader from "@/components/detail-header";
import RefreshList from "@/components/refresh-list";
import EmptyPage from "@/components/empty-page";
// import Category from "@/components/home/category";
import OfficeListCell from "@/components/office-list-cell";
import OtherHeader from "@/components/other-header";
export default {
    name: "jobfair-detail-list",
    components: {
        OtherHeader, OfficeListCell,
        // Category,
        EmptyPage, RefreshList, DetailHeader},
    data() {
        return {
            info: {},
            searchList: [],
            list: [],
            pageSize: 15,
            pageIndex: 1,
            searchParams: {
                postName: "",
                // keyword: ""
            }
        }
    },
    computed: {
        id() {
            return this.$route.query.id;
        },
        userInfo() {
            return this.$store.state.userInfo;
        }
    },
    activated() {
        this.pageIndex = 1;
        this.loadPageList();
        this.loadData();
    },
    mounted() {
        this.pageIndex = 1;
        this.loadPageList();
    },
    methods: {
        async loadData() {
            // path /BaseInstitutions/GetInstitutionsInfo
            const params = {
                id: this.id
            };
            const res = await $app.httpGet({
                path: `/BaseJobFair/GetJobFairInfo`,
                params: params,
            });
            if (res.ok) {
                const item = res.data;
                // 设置状态
                let current = new Date().getTime();
                let start = new Date(item.StartTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                let end = new Date(item.EndTime.replace(/-/g, "/").replace(/T/g, " ")).getTime();
                if (current < start) {
                    item.status = 0;
                } else if (current >= start && current <= end) {
                    item.status = 1;
                } else if (current > end) {
                    item.status = 2;
                }
                item.startTime = $app.dateFormatChinese(item.StartTime);
                item.endTime = $app.dateFormatChinese(item.EndTime);
                this.info = item;
            }
        },
        onCategorySelect(item) {
            console.log(item)
            this.searchParams.postName = item.name;
            this.pageIndex = 1;
            this.loadPageList();
        },
        async loadPageList() {
            // let salary = this.searchList[2] || '~';
            const params = {
                // areaName: this.searchList[0] || '',
                postName: this.searchParams.postName || "",
                // salaryMin: salary.split("~")[0] || 0,
                // salaryMax: salary.split("~")[1] || 0,
                // details: this.searchList[3] || '',
                // postType: 1,
                pageSize: this.pageSize,
                pageIndex: this.pageIndex,
                jobFairId: this.id
            };
            const res = await $app.httpGet({
                path: `/BaseJobFair/GetPostByJobFair`,
                params: params,
            });
            this.$refs['refreshList'].stop();
            if (res.ok) {
                let list = this.list || [];
                let newList = res.data || [];
            
                newList = newList.map(item => {
                    let Requirements = item.Requirements || "";
                    let Welfare = item.Welfare || "";
                    // 汉字逗号改为英文逗号
                    Requirements = Requirements.replace(/，/g, ',').replace(/、/g, ",");
                    item.RequirementsList = Requirements.split(",");
                
                    Welfare = Welfare.replace(/，/g, ",").replace(/、/g, ",");
                    item.WelfareList = Welfare.split(",");
                
                    if (item.RequirementsList[0] == "") {
                        item.RequirementsList = [];
                    }
                    if (item.WelfareList[0] == "") {
                        item.WelfareList = [];
                    }
                
                    item.upTimeText = $app.dateFormatChinese(item.BaseModifyTime);
                    return item;
                });
            
                if (this.pageIndex == 1) {
                    list = newList;
                } else {
                    list = list.concat(newList);
                    if (newList.length < this.pageSize) {
                        this.$refs['refreshList'].finish();
                    }
                }
                this.list = list;
            }
        },
        onPullDownRefresh() {
            this.pageIndex = 1;
            this.loadPageList();
        },
        onReachBottom() {
            this.pageIndex += 1;
            this.loadPageList();
        }
    }
}
</script>

<style scoped lang="less">
.policy-detail {
    width: calc(18rem - 0.4rem);
    margin: 0.4rem auto;
    & .card {
        background-color: #Fff;
        padding: 0.4rem;
        border-radius: 0.12rem;
        text-align: left;
        & .title {
            font-size: 0.4rem;
        }
        & .time {
            font-size: .28rem;
            color: #999999;
            line-height: .60rem;
        }
    }
}
</style>
